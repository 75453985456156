<script setup lang="ts">
import store from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { DesktopPanelTheme, MobilePanelTheme } from ".";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const accountId = computed(() => route.params.id as string);

const menuDefault = computed(() => [
  {
    key: "accounts",
    icon: "change",
    label: t("common.labels.change_account"),
    url: "/accounts",
  },
  {
    key: "plans",
    icon: "plans",
    label: t("common.labels.plans"),
    url: "/plans",
  },
]);

const menuAdmin = computed(() => [
  {
    key: "home",
    icon: "home-line",
    label: t("common.labels.home"),
    url: `/${accountId.value}/home`,
  },
  {
    key: "production",
    icon: "plant",
    label: t("common.labels.production"),
    url: `/${accountId.value}/production`,
  },
  {
    key: "finances",
    icon: "finances",
    label: t("common.labels.finances"),
    url: `/${accountId.value}/finances`,
  },
  {
    key: "stock",
    icon: "stock",
    label: t("common.labels.stock"),
    url: `/${accountId.value}/stock`,
  },
  {
    key: "team",
    icon: "group",
    label: t("common.labels.team"),
    url: `/${accountId.value}/team`,
  },
]);

const menuAffiliate = computed(() => [
  {
    key: "home",
    icon: "home-line",
    label: t("common.labels.home"),
    url: `/${accountId.value}/home`,
  },
  {
    key: "production",
    icon: "plant",
    label: t("common.labels.production"),
    url: `/${accountId.value}/production`,
  },
  {
    key: "finances",
    icon: "finances",
    label: t("common.labels.finances"),
    url: `/${accountId.value}/finances`,
  },
  {
    key: "stock",
    icon: "stock",
    label: t("common.labels.stock"),
    url: `/${accountId.value}/stock`,
  },
]);

const menuList = computed(() => {
  if (store.getters.type === "admin" || store.getters.type === "manager")
    return menuAdmin.value;

  if (store.getters.type === "affiliate" || store.getters.type === "assistant")
    return menuAffiliate.value;

  return menuDefault.value;
});
</script>

<template>
  <template v-if="!$store.getters.mobile">
    <desktop-panel-theme :menu-list="menuList">
      <template #main>
        <slot></slot>
      </template>
    </desktop-panel-theme>
  </template>
  <template v-else>
    <mobile-panel-theme :menu-list="menuList" :account-id="accountId">
      <template #main>
        <slot></slot>
      </template>
    </mobile-panel-theme>
  </template>
</template>