export function maskNumber(text: string) {
    if (!text) return text;

    return text.replace(/[^\d.]/g, '');
}

export function maskDateBR(text: string, return_default = null) {
    if (!text) return return_default ?? text;

    let formatted = text.replace(/\D/g, '');
    if (formatted.length > 2)
        formatted = formatted.replace(/^(\d{2})(\d)/, '$1/$2');
    if (formatted.length > 5)
        formatted = formatted.replace(/^(\d{2})\/(\d{2})(\d{1,4})/, '$1/$2/$3');

    return formatted.substring(0, 10);
}

export function maskDateUS(text: string, return_default = null) {
    if (!text) return return_default ?? text;

    let formatted = text.replace(/\D/g, '');

    if (formatted.length === 8) {
        formatted = formatted.replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1');
    }

    return formatted.substring(0, 10);
}

export function maskCEP(text: string) {
    if (!text) return text;

    let formatted = text.replace(/\D/g, '');
    if (formatted.length > 2)
        formatted = formatted.replace(/^(\d{2})(\d)/, '$1.$2');
    if (formatted.length > 5)
        formatted = formatted.replace(/^(\d{2})\.(\d{3})(\d{1,3})/, '$1.$2-$3')
    return formatted.substring(0, 10);
}

export function maskPhone(text: string) {
    if (!text) return text;

    let formatted = text.replace(/\D/g, '');
    if (formatted.length > 2)
        formatted = formatted.replace(/^(\d{2})(\d)/, '($1) $2');

    if (formatted.length < 14) {
        formatted = formatted.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
        formatted = formatted.replace(/(\d{5})(\d)/, '$1-$2');
    }

    return formatted.substring(0, 15);
}

export function maskCPF(text: string) {
    if (!text) return text;

    let formatted = text.replace(/\D/g, '');
    if (formatted.length > 3)
        formatted = formatted.replace(/^(\d{3})(\d)/, '$1.$2');

    if (formatted.length > 7)
        formatted = formatted.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');

    if (formatted.length > 11)
        formatted = formatted.replace(/\.(\d{3})(\d)/, '.$1-$2');

    return formatted.substring(0, 14);
}

export function maskCNPJ(text: string) {
    if (!text) return text;

    let formatted = text.replace(/\D/g, '');
    if (formatted.length > 2)
        formatted = formatted.replace(/^(\d{2})(\d)/, '$1.$2');

    if (formatted.length > 6)
        formatted = formatted.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

    if (formatted.length > 10)
        formatted = formatted.replace(/\.(\d{3})(\d)/, '.$1/$2');

    if (formatted.length > 15)
        formatted = formatted.replace(/(\d{4})(\d)/, '$1-$2');

    return formatted.substring(0, 18);
}


export function maskCurrencyBR(value: string | number): string {
    if (!value) return '';

    let formatted = value.toString().replace(/\D/g, '');

    formatted = (parseFloat(formatted) / 100).toFixed(2).replace('.', ',');

    formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return formatted;
}

export function currencyBRtoFloat(currency: string) {
    if (!currency) return "0";

    const formatted = currency.replace(".", "").replace(',', '.');

    return Number.parseFloat(formatted).toFixed(2);
}

export function currencyFloatToBR(currency: string): string {
    if (!currency) return "";

    const formatted = parseFloat(currency).toFixed(2);
    return maskCurrencyBR(formatted);
}
