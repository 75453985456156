<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";

import { maskDateBR, maskCurrencyBR } from "@/common/utils/mask";

import {
  baseAvatar,
  baseInput,
  baseSelect,
  baseTextarea,
} from "@/common/components";

import type { FinanceType, FinancePayloadType } from "@/common/types/finances";

const { t, tm } = useI18n();

const props = defineProps<{
  data: FinanceType | null;
}>();

const emit = defineEmits(["update-data", "validate"]);

const defaultPayload = {
  type: "ENTRANCE",
  status: "",
  title: "",
  price: 0,
  date: moment().format("DD/MM/YYYY"),
  description: "",
};

const payload = ref<FinancePayloadType>({ ...defaultPayload });

const typeOptions = computed(() =>
  Object.entries(tm("finance.enum.type") as Record<string, string>).map(
    ([value, option]) => ({
      option,
      value,
    })
  )
);

const statusOptions = computed(() =>
  Object.entries(tm("finance.enum.status") as Record<string, string>).map(
    ([value, option]) => ({
      option,
      value,
    })
  )
);

function validate() {
  const { type, status, title, price, date } = payload.value;

  const valid = type && status && title && price && date;

  emit("validate", valid);
}

function update() {
  emit("update-data", {
    ...payload.value,
    date: moment(payload.value.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
  });
  validate();
}

onMounted(() => {
  if (!props.data) return;

  const { type, status, title, price, date, description } = props.data;

  payload.value = {
    type,
    status,
    title,
    price: maskCurrencyBR(Number.parseFloat(String(price || "0")).toFixed(2)),
    date: moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    description,
  };

  update();
});
</script>

<template>
  <section class="form-content">
    <form class="flex flex-col gap-4">
      <div class="flex flex-row gap-4">
        <div class="flex-1">
          <base-select
            v-model="payload.type"
            :value="payload.type"
            :options="typeOptions"
            :is-empty="false"
            required
            @change="update()"
          >
            <template #label> {{ t("common.labels.type") }} * </template>
          </base-select>
        </div>
        <div class="flex-1">
          <base-input
            v-model="payload.date"
            :value="payload.date"
            placeholder="dd/mm/yyyy"
            :mask="maskDateBR"
            required
            @blur="update()"
          >
            <template #label> {{ t("common.labels.date") }} *</template>
          </base-input>
        </div>

        <div class="flex-1">
          <base-select
            v-model="payload.status"
            :value="payload.status"
            :options="statusOptions"
            :is-empty="false"
            required
            @change="update()"
          >
            <template #label> {{ t("common.labels.status") }} * </template>
          </base-select>
        </div>
      </div>

      <base-input
        v-model="payload.price"
        :value="payload.price"
        :mask="maskCurrencyBR"
        placeholder="0,0"
        required
        @blur="update()"
      >
        <template #label> {{ t("common.labels.value") }} *</template>
      </base-input>

      <base-input
        v-model="payload.title"
        :value="payload.title"
        :placeholder="t('common.placeholders.title')"
        required
        @blur="update()"
      >
        <template #label> {{ t("common.labels.title") }} *</template>
      </base-input>

      <base-textarea
        v-model="payload.description"
        :value="payload.description"
        :placeholder="t('common.placeholders.describe')"
        @blur="update()"
      >
        <template #label>
          {{ t("common.labels.description") }}
        </template>
      </base-textarea>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.form-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

@media screen and (max-width: 1024px) {
  .form-content {
    padding-inline: 0.5rem;
    .flex-row {
      flex-direction: column;
    }
  }
}
</style>