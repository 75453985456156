import { common } from "./common";
import { error } from "./error";
import { finance } from "./finance";
import { user } from "./user";
import { plan } from "./plan";
import { production } from "./production";
import { stock } from "./stock";
import { team } from "./team";

export const language = {
    translations: {
        common,
        error,
        finance,
        user,
        plan,
        production,
        stock,
        team,
    },
};