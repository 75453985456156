import clientAxios from "../client";
import { formatQueryString } from "@/common/utils/common";
import type { QueryType } from "@/common/types/common";
import type { StockPayloadType, StockMovementPayloadType } from "@/common/types/stock";

export async function stockList(
    account_id: string,
    query?: QueryType
) {
    const queryString = formatQueryString(query);
    return await clientAxios.get(
        `member/${account_id}/stock/list${queryString}`
    );
}

export async function stockGet(
    account_id: string,
    stock_id: string,
) {
    return await clientAxios.get(
        `member/${account_id}/stock/${stock_id}/get`
    );
}

export async function stockAdd(
    account_id: string,
    payload: StockPayloadType
) {
    return await clientAxios.post(
        `member/${account_id}/stock/add`,
        payload
    );
}

export async function stockPut(
    account_id: string,
    stock_id: string,
    payload: StockPayloadType
) {
    return await clientAxios.put(
        `member/${account_id}/stock/${stock_id}/edit`,
        payload
    );
}

export async function stockDel(
    account_id: string,
    stock_id: string,
) {
    return await clientAxios.del(
        `member/${account_id}/stock/${stock_id}/del`
    );
}

// REPORT STOCK
export async function stockReport(
    account_id: string,
    type: string,
    start_date: string,
    end_date: string,
) {
    const queryString = formatQueryString({ type, start_date, end_date });
    return await clientAxios.get(
        `member/${account_id}/stock/report${queryString}`,
    );
}

// MOVEMENT STOCK
export async function stockMovementList(
    account_id: string,
    query?: QueryType
) {
    const queryString = formatQueryString(query);
    return await clientAxios.get(
        `member/${account_id}/movement/stock/list${queryString}`,
    );
}

export async function stockMovementProductionList(
    account_id: string,
    production_id: string,
    query?: QueryType
) {
    const queryString = formatQueryString(query);
    return await clientAxios.get(
        `member/${account_id}/movement/stock/production/${production_id}/list${queryString}`,
    );
}

export async function stockMovementAdd(
    account_id: string,
    payload: StockMovementPayloadType
) {
    return await clientAxios.post(
        `member/${account_id}/movement/stock/add`,
        payload
    );
}

export async function stockMovementDel(
    account_id: string,
    movement_id: string,
) {
    return await clientAxios.del(
        `member/${account_id}/movement/stock/${movement_id}/del`,
    );
}