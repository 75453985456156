<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { toast } from "@/common/utils/toast";
import { baseInput, svgIcon } from "@/common/components";

import { userRecover } from "@/common/api/rest/user";

const { t } = useI18n();
const router = useRouter();

const email = ref<string>("");

const error = ref<{ [key: string]: string }>({});

function goTo(url: string) {
  router.push(url);
}

function goToValid() {
  router.push({
    path: "/auth/recover/valid",
    query: {
      hash: window.btoa(JSON.stringify({ email: email.value, code: null })),
    },
  });
}

function isValid() {
  error.value = {};

  if (!email.value.includes("@")) error.value.email = t("error.invalid");
  if (email.value.trim().length === 0) error.value.email = t("error.required");

  return Object.keys(error.value).length === 0;
}

function submit() {
  if (!isValid()) return;

  userRecover({
    email: email.value,
  })
    .then((response) => {
      const { success, data } = response;
      if (success) {
        toast({ message: data.message, type: "success" });
        goToValid();
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}
</script>

<template>
  <main class="screen">
    <section class="box-login shadow">
      <div class="left">
        <img :src="require('@/assets/images/logo.png')" />
      </div>

      <div class="right">
        <h2>
          {{ t("common.texts.recover_password").toUpperCase() }}
        </h2>
        <form @submit.prevent="submit()">
          <base-input
            v-model="email"
            :placeholder="t('common.placeholders.email')"
            background="#f1f1f1"
            maxLength="50"
          >
            <template #label>
              {{ t("common.labels.email") }}
            </template>
            <template v-if="error.email" #error>
              {{ error.email }}
            </template>
          </base-input>

          <div class="actions">
            <button class="btn btn-primary">
              {{ t("common.labels.send").toUpperCase() }}
            </button>
            <div class="recover">
              <div>
                {{ t("common.texts.remembered_password") }}
              </div>
              <button
                type="button"
                class="btn btn-sm btn-none font-bold"
                @click="goTo('/auth/login')"
              >
                {{ t("common.labels.login").toUpperCase() }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>
.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: var(--black-background);

  .box-login {
    display: flex;
    flex-direction: row;
    width: 650px;
    height: 500px;
    background: var(--white);
    border-radius: 0.75rem;

    .left {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 150px;
      }
    }

    .right {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding-inline: 8%;

      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.875rem;
      }

      .actions {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-block-start: 1rem;
        gap: 1rem;

        .recover {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.75rem;
          margin-block-start: 1.25rem;
          margin-block-end: 1rem;

          div:nth-child(1) {
            font-size: 0.875rem;
            width: fit-content;
            text-align: center;
          }

          div:nth-child(2) {
            cursor: pointer;
            user-select: none;
            width: fit-content;
            text-align: center;
            font-weight: bold;
            color: var(--primary);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .screen {
    align-items: center;
    justify-content: flex-start;
    padding-block: 5%;

    .box-login {
      flex-direction: column;
      width: 90%;
      height: auto;
      margin-block: auto;
      padding-block: 1.5rem;

      .left {
        width: 100%;
        padding-block: 1.5rem;
      }

      .right {
        width: 84%;
      }
    }
  }
}
</style>