<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";

import { toast } from "@/common/utils/toast";
import { currencyBRtoFloat, currencyFloatToBR } from "@/common/utils/mask";

import {
  productionByID,
  productionPatch,
  productionFinish,
  productionCancel,
} from "@/common/api/rest/production";
import {
  financeProductionList,
  financeProductionAdd,
  financePut,
  financeDel,
} from "@/common/api/rest/finance";
import {
  stockMovementProductionList,
  stockMovementAdd,
  stockMovementDel,
} from "@/common/api/rest/stock";

import type { ProductionType } from "@/common/types/production";
import type { StockType } from "@/common/types/stock";
import type { FinanceType, FinancePayloadType } from "@/common/types/finances";

import { PanelTheme } from "@/common/themes";
import { productionForm, SearchStock, financeForm } from "@/components";
import {
  baseConfirm,
  baseModal,
  baseTable,
  tabMenu,
  svgIcon,
} from "@/common/components";

const { t, tm } = useI18n();
const route = useRoute();
const router = useRouter();

const accountId = computed(() => route.params.id as string);
const productionId = computed(() => route.params.production as string);

const statusEnum = computed(
  () => tm("stock.enum.status") as Record<string, string>
);

const unitEnum = computed(
  () => tm("stock.enum.units_name") as Record<string, string>
);

const tabList = computed(() => [
  {
    key: "production",
    label: t("common.labels.production").toUpperCase(),
  },
  {
    key: "stock",
    label: t("production.labels.stock_consumption").toUpperCase(),
  },
  {
    key: "negotiation",
    label: t("production.labels.negotiations").toUpperCase(),
  },
]);

const stockTableHeader = computed(() => [
  { key: "code", label: t("common.labels.code") },
  { key: "is_culture", label: t("production.labels.culture") },
  { key: "name", label: t("common.labels.name") },
  { key: "amount", label: t("common.labels.amount") },
  { key: "unit_name", label: t("common.labels.unit") },
  { key: "price", label: t("common.labels.value") },
  { key: "total", label: t("common.labels.total") },
]);

const financeTableHeader = computed(() => [
  { key: "type", label: t("common.labels.type") },
  { key: "date", label: t("common.labels.date") },
  { key: "title", label: t("common.labels.title") },
  { key: "status", label: t("common.labels.status") },
  { key: "price", label: t("common.labels.value") },
]);

const financeTypeOptions = computed(
  () => tm("finance.enum.type") as Record<string, string>
);

const financeStatusOptions = computed(
  () => tm("finance.enum.status") as Record<string, string>
);

const tabActive = ref("production");

const production = ref<ProductionType | null>(null);
const stock = ref<StockType[]>([]);
const negotiation = ref<FinanceType[]>([]);
const negotiationPayload = ref<FinancePayloadType | null>(null);

const stockSearch = ref<string | null>(null);
const negotiationSearch = ref<string | null>(null);
const selectedStock = ref<StockType | null>(null);
const selectedCulture = ref<{
  stock: StockType;
  amount: string;
  type: string;
} | null>(null);
const selectedNegotiation = ref<FinanceType | null>(null);

const productionShowModalFinish = ref(false);
const productionShowModalCancel = ref(false);
const stockShowModalAdd = ref(false);
const stockShowModalDel = ref(false);
const cultuteShowModalAdd = ref(false);
const negotiationShowModalAdd = ref(false);
const negotiationShowModalDel = ref(false);

const isNegotiationEdit = ref(false);
const cultureValid = ref(false);
const negotiationValid = ref(false);

function back() {
  router.push(`/${accountId.value}/production`);
}

async function fetchData() {
  await productionByID(accountId.value, productionId.value)
    .then((response) => {
      const { success, data } = response;

      if (!success) {
        toast({ message: data.message, type: "error" });
        return;
      }
      production.value = data;
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

async function fetchStockData() {
  await stockMovementProductionList(accountId.value, productionId.value, {
    search: stockSearch.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (!success) {
        toast({ message: data.message, type: "error" });
        return;
      }

      stock.value = data.list;
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

async function fetchNegotiation() {
  await financeProductionList(accountId.value, productionId.value, {
    search: negotiationSearch.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (!success) {
        toast({ message: data.message, type: "error" });
        return;
      }

      negotiation.value = data.list;
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function patchData(data: Partial<ProductionType>) {
  productionPatch(accountId.value, productionId.value, data)
    .then((response) => {
      const { success, data } = response;

      fetchData();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

async function selectTab(tab: string) {
  tabActive.value = tab;
  fetchData();
  fetchStockData();
  fetchNegotiation();
}

function finishProduction() {
  productionShowModalFinish.value = true;
}

function finishProductionCancel() {
  productionShowModalFinish.value = false;
}

function finishProductionConfirm() {
  productionFinish(accountId.value, productionId.value)
    .then((response) => {
      const { success, data } = response;

      fetchData();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      productionShowModalFinish.value = false;
    });
}

function cancelProduction() {
  productionShowModalCancel.value = true;
}

function cancelProductionCancel() {
  productionShowModalCancel.value = false;
}

function cancelProductionConfirm() {
  productionCancel(accountId.value, productionId.value)
    .then((response) => {
      const { success, data } = response;

      fetchData();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      productionShowModalCancel.value = false;
    });
}

// CULTURE AND STOCK
function searchStockItem(data: string) {
  stockSearch.value = data;
}

function addCulture() {
  cultuteShowModalAdd.value = true;
}

function addCultureConfirm() {
  if (!selectedCulture.value || !cultureValid.value) return;

  store.commit("SET_LOADING", true);

  stockMovementAdd(accountId.value, {
    is_culture: true,
    production_id: productionId.value,
    stock_id: selectedCulture.value.stock.stock_id,
    amount: selectedCulture.value.amount,
    type: selectedCulture.value.type,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        addStockCancel();
        fetchStockData();
        fetchData();
        toast({ message: data.message, type: "success" });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function addStock() {
  stockShowModalAdd.value = true;
}

function addStockCancel() {
  cultuteShowModalAdd.value = false;
  stockShowModalAdd.value = false;
  stockShowModalDel.value = false;
  selectedStock.value = null;
  selectedCulture.value = null;
}

function addStockConfirm() {
  if (!selectedCulture.value || !cultureValid.value) return;

  store.commit("SET_LOADING", true);

  stockMovementAdd(accountId.value, {
    is_culture: false,
    production_id: productionId.value,
    type: selectedCulture.value.type,
    stock_id: selectedCulture.value.stock.stock_id,
    amount: selectedCulture.value.amount,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        addStockCancel();
        fetchData();
        fetchStockData();
        toast({ message: data.message, type: "success" });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function removeStock(data: StockType) {
  selectedStock.value = data;
  stockShowModalDel.value = true;
}

function removeStockCancel() {
  selectedStock.value = null;
  stockShowModalDel.value = false;
}

function removeStockConfirm() {
  if (!selectedStock.value) return;

  store.commit("SET_LOADING", true);

  stockMovementDel(accountId.value, selectedStock.value.movement_id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        addStockCancel();
        fetchStockData();
        fetchData();
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

// NEGOTIATION
function searchNegotiation(data: string) {
  negotiationSearch.value = data;
}

function addNegotiation() {
  negotiationShowModalAdd.value = true;
}

function addNegotiationConfirm() {
  if (!negotiationPayload.value || !negotiationValid.value) return;

  store.commit("SET_LOADING", true);

  financeProductionAdd(accountId.value, productionId.value, {
    ...negotiationPayload.value,
    price: currencyBRtoFloat(negotiationPayload.value.price),
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        submitNegotiationCancel();
        fetchNegotiation();
        toast({ message: data.message, type: "success" });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function editNegotiation(data: FinanceType) {
  negotiationShowModalAdd.value = true;
  isNegotiationEdit.value = true;
  selectedNegotiation.value = data;
}

function editNegotiationConfirm() {
  if (!negotiationPayload.value || !negotiationValid.value) return;

  store.commit("SET_LOADING", true);

  financePut(accountId.value, selectedNegotiation.value.finance_id, {
    ...negotiationPayload.value,
    price: currencyBRtoFloat(negotiationPayload.value.price),
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        submitNegotiationCancel();
        fetchNegotiation();
        toast({ message: data.message, type: "success" });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function submitNegotiationCancel() {
  negotiationShowModalAdd.value = false;
  negotiationShowModalDel.value = false;
  isNegotiationEdit.value = false;
  selectedNegotiation.value = null;
}

function submitNegotiation() {
  if (isNegotiationEdit.value) {
    editNegotiationConfirm();
  } else {
    addNegotiationConfirm();
  }
}

function removeNegotiation(data: FinanceType) {
  negotiationShowModalDel.value = true;
  selectedNegotiation.value = data;
}

function removeNegotiationConfirm() {
  if (!selectedNegotiation.value) return;

  store.commit("SET_LOADING", true);

  financeDel(accountId.value, selectedNegotiation.value.finance_id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        submitNegotiationCancel();
        fetchNegotiation();
        toast({ message: data.message, type: "success" });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

onMounted(async () => {
  store.commit("SET_LOADING", true);
  await fetchData();
  store.commit("SET_LOADING", false);
});
</script>

<template>
  <panel-theme>
    <template v-if="production">
      <article class="production-page">
        <section class="header box">
          <div class="title">
            <svg-icon icon="plant" size="2.5rem" />
            {{ t("common.labels.production") }} - {{ production?.code }}
          </div>

          <div class="actions">
            <button class="btn btn-sm btn-default py-2" @click="back()">
              <svg-icon icon="close" size="1.25rem" />
            </button>
          </div>
        </section>

        <tab-menu
          :tabs="tabList"
          :active="tabActive"
          :tooltip="false"
          width="fit-content"
          padding-inline="1.5rem"
          @select:tab="selectTab"
        >
          <template #content>
            <section class="tab-production">
              <template v-if="tabActive === 'production'">
                <div class="header">
                  <template v-if="!production.finished && !production.canceled">
                    <div class="actions">
                      <button
                        type="button"
                        class="btn btn-md btn-black px-5 uppercase"
                        @click="finishProduction()"
                      >
                        {{ t("production.labels.finish") }}
                      </button>

                      <button
                        type="button"
                        class="btn btn-md btn-default px-5 uppercase"
                        @click="cancelProduction()"
                      >
                        {{ t("production.labels.cancel") }}
                      </button>
                    </div>
                  </template>
                </div>

                <template v-if="production.culture">
                  <div class="culture">
                    <div class="line">
                      <div class="label">
                        {{ t("production.labels.culture") }}:
                      </div>
                      <div class="value">
                        {{ production.culture.name }}
                      </div>
                    </div>

                    <div class="line">
                      <div class="label">
                        {{ t("production.labels.description") }}:
                      </div>
                      <div class="value">
                        {{ production.culture.description }}
                      </div>
                    </div>

                    <div class="line">
                      <div class="label">
                        {{ t("production.labels.unit_name") }}:
                      </div>
                      <div class="value">
                        {{ unitEnum[production.culture.unit_name] }}
                      </div>
                    </div>

                    <div class="line">
                      <div class="label">
                        {{ t("production.labels.amount_used") }}:
                      </div>
                      <div class="value">
                        {{ production.culture.used }}
                      </div>
                    </div>
                  </div>
                </template>

                <production-form
                  :data="production"
                  :patch="true"
                  @update-data="patchData($event)"
                />
              </template>

              <template v-else-if="tabActive === 'stock'">
                <template v-if="!production.culture">
                  <div class="header mb-4">
                    <div class="actions">
                      <button
                        type="button"
                        class="btn btn-md btn-black px-5 uppercase"
                        @click="addCulture()"
                      >
                        {{ t("production.labels.add_culture") }}
                      </button>
                    </div>
                  </div>
                </template>

                <baseTable
                  :header="stockTableHeader"
                  :data="stock"
                  :paginated="false"
                  :options="true"
                  :del="true"
                  @searching="searchStockItem($event)"
                  @btn:add="addStock()"
                  @btn:del="removeStock($event)"
                >
                  <template #is_culture="{ data }">
                    {{
                      data.is_culture
                        ? t("common.labels.yes")
                        : t("common.labels.not")
                    }}
                  </template>

                  <template #unit_name="{ data }">
                    {{ unitEnum[data.unit_name] }}
                  </template>

                  <template #price="{ data }">
                    R$ {{ currencyFloatToBR(data.price) }}
                  </template>

                  <template #total="{ data }">
                    R$ {{ currencyFloatToBR(data.price * data.amount) }}
                  </template>
                </baseTable>
              </template>

              <template v-else-if="tabActive === 'negotiation'">
                <baseTable
                  :header="financeTableHeader"
                  :data="negotiation"
                  :paginated="false"
                  :options="true"
                  :edit="true"
                  :del="true"
                  @searching="searchNegotiation"
                  @btn:add="addNegotiation()"
                  @btn:edit="editNegotiation($event)"
                  @btn:del="removeNegotiation($event)"
                >
                  <template #status="{ data }">
                    {{ financeStatusOptions[data.status] }}
                  </template>

                  <template #title="{ data }">
                    <div class="w-max-content">
                      {{ data.title }}
                    </div>
                  </template>

                  <template #type="{ data }">
                    {{ financeTypeOptions[data.type] }}
                  </template>

                  <template #price="{ data }">
                    {{ currencyFloatToBR(data.price) }}
                  </template>
                </baseTable>
              </template>
            </section>
          </template>
        </tab-menu>
      </article>
    </template>

    <base-modal
      :show="cultuteShowModalAdd"
      :show-button-confirm="true"
      :title="t('production.modals.titles.add_culture').toUpperCase()"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="addStockCancel()"
      @confirm="addCultureConfirm()"
    >
      <template #content>
        <search-stock
          status="IN_STOCK"
          type="CULTURE"
          :account-id="accountId"
          @update-data="selectedCulture = $event"
          @is:valid="cultureValid = $event"
        />
      </template>
    </base-modal>

    <base-modal
      :show="stockShowModalAdd"
      :show-button-confirm="true"
      :title="t('production.modals.titles.add_consumption').toUpperCase()"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="addStockCancel()"
      @confirm="addStockConfirm()"
    >
      <template #content>
        <search-stock
          status="IN_STOCK"
          :account-id="accountId"
          @update-data="selectedCulture = $event"
          @is:valid="cultureValid = $event"
        />
      </template>
    </base-modal>

    <base-modal
      :show="negotiationShowModalAdd"
      :show-button-confirm="true"
      :title="t('finance.labels.add_negotiation').toUpperCase()"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="submitNegotiationCancel()"
      @confirm="submitNegotiation()"
    >
      <template #content>
        <finance-form
          :data="selectedNegotiation"
          @update-data="negotiationPayload = $event"
          @validate="negotiationValid = $event"
        />
      </template>
    </base-modal>

    <base-confirm
      :show="productionShowModalFinish"
      :title="t('production.modals.titles.finish_production').toUpperCase()"
      :message="t('production.modals.messages.finish_production')"
      @cancel="finishProductionCancel()"
      @confirm="finishProductionConfirm()"
    />

    <base-confirm
      :show="productionShowModalCancel"
      :title="t('production.modals.titles.cancel_production').toUpperCase()"
      :message="t('production.modals.messages.cancel_production')"
      @cancel="cancelProductionCancel()"
      @confirm="cancelProductionConfirm()"
    />

    <base-confirm
      :show="stockShowModalDel"
      :title="t('stock.modals.titles.remove_movement').toUpperCase()"
      :message="t('stock.modals.messages.remove_movement')"
      @cancel="removeStockCancel()"
      @confirm="removeStockConfirm()"
    />

    <base-confirm
      :show="negotiationShowModalDel"
      :title="t('finance.modals.titles.remove_negotiation').toUpperCase()"
      :message="t('finance.modals.messages.remove_negotiation')"
      @cancel="submitNegotiationCancel()"
      @confirm="removeNegotiationConfirm()"
    />
  </panel-theme>
</template>

<style lang="scss" scoped>
.production-page {
  display: flex;
  flex-direction: column;
  gap: 1.54rem;

  .header {
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: space-between;
    gap: 1rem;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--text-medium);
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .box {
    width: inherit;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.25rem;
  }

  .tab-production {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    .header {
      display: flex;
      flex-direction: column;

      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.75rem;
      }
    }

    .culture {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border: 1px solid var(--border);
      border-radius: 0.5rem;
      padding: 1rem;

      .line {
        display: inline-flex;
        gap: 0.5rem;
        font-size: 1rem;

        .value {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .production-page {
    .header {
      .title {
        font-size: 1rem;

        .svgicon {
          width: 1.75rem;
          height: 1.75rem;
        }
      }

      .actions {
        button {
          padding-block: 0.25rem;
          padding-inline: 0.75rem;

          .svgicon {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }

    .tab-production {
      .culture {
        gap: 1.25rem;

        .line {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .label {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
</style>